<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>订单明细</template>
    </breadcrumb-nav>

    <!-- 卡片视图 -->
    <el-card>
      <el-card class="box-card goods-add" shadow="never">
        <el-button type="text" icon="el-icon-back" @click="$router.back(-1)">返回订单列表</el-button>
        <el-divider direction="vertical"></el-divider>
        <label>订单编号: {{ orderSn }}</label>
      </el-card>
      <div class="table-title-btm">订单商品信息</div>
      <el-table class="custom-order-table" :data="list" border stripe>
        <el-table-column label="商品图标">
          <template slot-scope="scope">
            <el-image
              style="height: 80px; width: 120px"
              :src="scope.row.image"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
        <el-table-column prop="skuDesc" label="规格信息"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">{{ scope.row.createTime | dateFormat }}</template>
        </el-table-column>
        <el-table-column prop="number" label="商品数量"></el-table-column>
        <el-table-column prop="price" label="商品价格" width="120px">
          <template slot-scope="scope">{{ scope.row.price | floatFormat }}</template>
        </el-table-column>
        <el-table-column prop="refundNum" label="状态" width="120px">
          <template slot-scope="scope">{{ scope.row.refundNum | formatRefundStatus }}</template>
        </el-table-column>
      </el-table>
      <div class="table-title-btm">快递地址信息</div>
      <el-table ref="deliverOrderTable"
                style="width: 100%;"
                :data="list" border>
        <el-table-column label="订单编号" width="180" align="center">
          <template slot-scope="scope">{{scope.row.orderSn}}</template>
        </el-table-column>
        <el-table-column label="收货人" width="100" align="center">
          <template slot-scope="scope">{{scope.row.consignee}}</template>
        </el-table-column>
        <el-table-column label="手机号码" width="120" align="center">
          <template slot-scope="scope">{{scope.row.mobile}}</template>
        </el-table-column>
        <el-table-column label="详细地址" align="center">
          <template slot-scope="scope">{{scope.row.fullAddr}}</template>
        </el-table-column>
        <el-table-column label="快递公司" width="160" align="center">
          <template slot-scope="scope"> {{scope.row.deliveryCompany}}</template>
        </el-table-column>
        <el-table-column label="快递单号" width="180" align="center">{{}}
          <template slot-scope="scope">{{scope.row.deliverySn}}</template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px; color:#92acd1" v-show="showTraces">最新物流状态：{{expressShowStatus}}</div>
      <el-timeline style="margin-top: 20px" v-show="showTraces">
        <el-timeline-item
          v-for="(trace, index) in traces"
          :key="index"
          :timestamp="trace.acceptTime">
          {{trace.acceptStation}}
        </el-timeline-item>
      </el-timeline>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../../common/BreadcrumbNav'
import { getExpressTrace, getOrderDetail } from '../../../network/order'

export default {
  name: 'OrderDetail',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      orderSn: '',
      orderId: 0,
      list: [],
      downloadUrlPrefix: '',
      traces: [],
      showTraces: false,
      expressShowStatus: ''
    }
  },
  filters: {
    formatRefundStatus (value) {
      if (value > 0) {
        return '已退款'
      } else {
        return '正常'
      }
    }
  },
  created () {
    console.log('this.$route.query.orderId', this.$route.query.orderId)
    console.log('this.$route.query.orderSn', this.$route.query.orderSn)
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
    this.orderSn = this.$route.query.orderSn
    this.orderId = this.$route.query.orderId
    this.getOrderDetail(this.$route.query.orderId)
  },
  methods: {
    getOrderDetail (orderId) {
      getOrderDetail({ orderId: orderId }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取订单明细失败', 'error')
        }
        this.list = res.data.data.goodsList
        var goodsId = 0
        var skuId = 0
        for (const item of this.list) {
          if (item.icon.includes('http')) {
            item.image = item.icon
          } else {
            item.image = this.downloadUrlPrefix + item.icon
          }
          goodsId = item.goodsId
          skuId = item.skuId
          item.deliveryCompany = res.data.data.deliveryCompany
          item.deliverySn = res.data.data.deliverySn
          item.address = res.data.data.address
          item.consignee = res.data.data.consignee
          item.mobile = res.data.data.mobile
          item.fullAddr = res.data.data.province + res.data.data.city + res.data.data.district + item.address + ' ' + item.consignee + ' ' + item.mobile
        }
        if ((res.data.data.orderStatus === 2 || res.data.data.orderStatus === 4) && res.data.data.deliverySn !== '') {
          getExpressTrace({ orderId: this.orderId, goodsId: goodsId, skuId: skuId }).then(res => {
            if (res.status !== 200) {
              return this.alertMessage('获取物流信息失败', 'error')
            }
            this.traces = res.data.data.traces.traces
            this.expressShowStatus = res.data.data.traces.showState
            this.showTraces = true
          })
        }
      })
    }
  }
}
</script>

<style>
.table-title{
  margin-bottom: 5px;
}
.table-title-btm{
  margin-top: 20px;
  margin-bottom: 5px;
}
</style>
